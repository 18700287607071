<template>
    <div class="estadoRemision">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-container>
            <v-dialog v-model="modalEstadoRemision" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloEstadoRemision }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmEstadoRemision" v-model="validoEstadoRemision" lazy-validation>
                                <v-text-field v-model="editEstadoRemision.nombre" :rules="reglaNombreEstadoRemision"
                                    prepend-inner-icon="mdi-format-text" label="Nombre" outlined clearable required>
                                </v-text-field>
                                <v-text-field v-model="editEstadoRemision.alias" :rules="reglaAliasEstadoRemision"
                                    prepend-inner-icon="mdi-format-color-text" label="Alias" outlined clearable
                                    required>
                                </v-text-field>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarEstadoRemision">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoEstadoRemision" color="success" class="mr-4"
                                        @click="guardarEstadoRemision">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEliminarEstadoRemision" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrarEliminarEstadoRemision">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimEstadoRemision">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- tabla -->
            <tabla :header="headersEstadoRemision" :body="estadosRemisiones" tituloTabla="Estado Remisiones"
                :accionAgregar="true" :exportar="true" @recargar="listarEstadoRemision" @agregar="agregarFabricante"
                @actualizar="editarEstadoRemision" @eliminar="eliminarEstadoRemision" />
            <!-- tabla fin -->
        </v-container>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "estadoRemision",
        components: {
            tabla
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables estadoRemision 
            UidEstadoRemision: null,
            buscarEstadoRemision: '',
            validoEstadoRemision: true,
            modalEstadoRemision: false,
            modalEliminarEstadoRemision: false,
            headersEstadoRemision: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre corto',
                    align: 'start',
                    value: 'alias',
                },
                {
                    text: 'Nombre / Estado Item',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            estadosRemisiones: [],
            reglaNombreEstadoRemision: [
                v => !!v || 'El nombre del estado es obligatorio.'
            ],
            reglaAliasEstadoRemision: [
                v => !!v || 'El alias del estado es obligatorio.'
            ],
            editedEstadoRemisionIndex: -1,
            editEstadoRemision: {
                nombre: '',
                alias: ''
            },
            defaultEstadoRemision: {
                nombre: '',
                alias: ''
            },
            // fin variables estadoRemision
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal estadoRemision 
            tituloEstadoRemision() {
                return this.editedEstadoRemisionIndex === -1 ? 'Nuevo Estado' : 'Editar Estado'
            },
        },

        watch: {
            // modal estadoRemision
            modalEstadoRemision(val) {
                val || this.cancelarEstadoRemision()
            },
            modalEliminarEstadoRemision(val) {
                val || this.cerrarEliminarEstadoRemision()
            },
            // fin modal estadoRemision
        },

        created() {
            // listar estadosRemisiones
            this.listarEstadoRemision()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarEstadoRemision() {
                try {
                    await fetch(`${this.url_api}/estado_remision`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.estadosRemisiones = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarFabricante() {
                this.modalEstadoRemision = true;
            },

            editarEstadoRemision(estado) {
                this.editedEstadoRemisionIndex = this.estadosRemisiones.indexOf(estado)
                this.editEstadoRemision = Object.assign({}, estado)
                this.modalEstadoRemision = true
                this.UidEstadoRemision = estado.Uid;
            },

            eliminarEstadoRemision(estado) {
                this.editedEstadoRemisionIndex = this.estadosRemisiones.indexOf(estado)
                this.editEstadoRemision = Object.assign({}, estado)
                this.modalEliminarEstadoRemision = true
                this.UidEstadoRemision = estado.Uid;
            },

            async confirmarElimEstadoRemision() {
                try {
                    await fetch(`${this.url_api}/estado_remision/${this.UidEstadoRemision}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.estadosRemisiones.splice(this.editedEstadoRemisionIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarEstadoRemision();
            },

            cancelarEstadoRemision() {
                this.$refs.frmEstadoRemision.reset()
                this.$refs.frmEstadoRemision.resetValidation()
                this.modalEstadoRemision = false
                this.$nextTick(() => {
                    this.editEstadoRemision = Object.assign({}, this.defaultEstadoRemision)
                    this.editedEstadoRemisionIndex = -1
                })
            },

            cerrarEliminarEstadoRemision() {
                this.modalEliminarEstadoRemision = false
                this.$nextTick(() => {
                    this.editEstadoRemision = Object.assign({}, this.defaultEstadoRemision)
                    this.editedEstadoRemisionIndex = -1
                })
            },

            async guardarEstadoRemision() {
                if (this.$refs.frmEstadoRemision.validate()) {
                    if (this.editedEstadoRemisionIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/estado_remision/${this.UidEstadoRemision}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editEstadoRemision)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.estadosRemisiones[this.editedEstadoRemisionIndex],
                                            this.editEstadoRemision);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidEstadoRemision = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/estado_remision`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editEstadoRemision)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarEstadoRemision();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al guardar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarEstadoRemision()
                }
            },
            // fin CRUD estadosRemisiones
        },
    }
</script>